import React from 'react';
import styled from 'styled-components';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card
} from 'antd';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import prepDefinition from 'src/components/meta/PdfDefinition';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
`;

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: '',
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      request: {}
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllRequest()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllRequest()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSortCurrent = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let request = this.state.currentRequests;
    if (order === 'ascend') {
      request.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      request.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSortPrevious = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let request = this.state.previousRequests;
    if (order === 'ascend') {
      request.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === 'descend') {
      request.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? 'black' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  onRequestApprove = request => {
    this.setState({ loading: true });
    request.status = 'None';
    this.props
      .approveRequest(request)
      .then(() => {
        this.props
          .findAllRequest()
          .then(() => {
            this.props
              .sendNotification(
                request.userId,
                [request.userId],
                'Admin has approved one of your requests',
                'adminChanged',
                request._id,
                false,
                'troef-die-skaal Admin'
              )
              .then(() => {
                const { vfs } = vfsFonts.pdfMake;
                pdfMake.vfs = vfs;
                let data = Object.assign({}, request);
                prepDefinition(data).then(definition => {
                  pdfMake
                    .createPdf(definition)
                    .download(
                      request.serial +
                      ' ' +
                      moment().format('YYYY-MM-DD') +
                      ' Approved Request.pdf',
                      () => {
                        this.setState({ loading: false });
                        Notify('success', 'Successfully approved request');
                      }
                    );
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  onExportPdf = request => {
    this.setState({ loading: true });
    const { vfs } = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    let data = Object.assign({}, request);
    prepDefinition(data).then(definition => {
      pdfMake
        .createPdf(definition)
        .download(
          request.serial +
          ' ' +
          moment().format('YYYY-MM-DD') +
          ' Approved Request.pdf',
          () => {
            this.setState({ loading: false });
            Notify('success', 'Successfully created PDF');
          }
        );
    });
  };
  onRequestReject = request => {
    this.setState({ loading: true });
    request.status = 'None';
    this.props
      .rejectRequest(request)
      .then(() => {
        this.props
          .findAllRequest()
          .then(() => {
            this.props
              .sendNotification(
                request.userId,
                [request.userId],
                'Admin has rejected one of your requests',
                'adminChanged',
                request._id,
                false,
                'troef-die-skaal Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Successfully rejected request');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  onEditRequestOutVisible = request => {
    this.setState({
      request: request,
      outVisible: true
    });
  };
  onEditRequestOut = () => {
    let request = this.state.request;
    this.setState({ loading: true, outVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllRequest()
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Successfully submitted');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleDeleteRequest = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteRequest;
    this.props
      .deleteRequest(data._id, data.pexunitId)
      .then(() => {
        this.props
          .findAllRequest()
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Deleted request',
              'Successfully deleted request.'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleSearchJob = e => {
    if (e.length > 3) {
      this.props
        .findJobNumbers(e)
        .then(suc => {
          this.setState({
            jobNumbers: suc.jobNumbers
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else {
      this.setState({
        jobNumbers: []
      });
    }
  };
  handleSearchTechnician = e => {
    if (e.length > 3) {
      this.props
        .findTechnicians(e)
        .then(suc => {
          this.setState({
            technicians: suc.technicians
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else {
      this.setState({
        technicians: []
      });
    }
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteRequest: '' });
  };
  checkQty = e => {
    let setQty = e;
    if (e !== '') {
      if (this.state.request.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false
        });
      }
    }
  };
  render() {
    const { currentRequests, previousRequests } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    // let technicianOptions = this.state.technicians.map(d => (
    //   <Option key={d.username} value={d.name}>
    //     {d.name + ' - ' + d.username.split('|')[1]}
    //   </Option>
    // ));
    // technicianOptions.unshift(<Option key={"Field Service"} value={"Field Service"}>
    //   {"Field Service"}
    // </Option>)
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: 'center' }}
        >
          <Col xs={24} md={12} style={{ marginLeft: '-0.8em' }}>
            <CheckOutlined
              style={{
                fontSize: 25,
                color: '#EC8144',
                marginRight: 7,
                marginBottom: 20
              }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>PEX Requests</span>
          </Col>
          <Col
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            xs={24}
            md={12}
          />
        </Row>
        <Spin spinning={this.state.loading}>
          <Tabs style={{ overflow: 'visible' }}>
            <TabPane tab="Current" key="1">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      defaultPageSize: 25
                    }}
                    expandedRowRender={
                      window.innerWidth <= 420
                        ? record => (
                          <Card
                            size="small"
                            title={record.itemCode}
                            style={{ width: 300, marginLeft: -35 }}
                          >
                            <p>Brand: {record.brand}</p>
                            <p>Model: {record.model}</p>
                            <p>Rebuild Job: {record.jobNumber}</p>
                            <p>Description: {record.description}</p>
                            <p>Serial: {record.serial}</p>
                            <p>Type: {record.type}</p>
                            <p>Site: {record.site}</p>
                            <p>Supply Job: {record.requisitionNumber}</p>
                          </Card>
                        )
                        : null
                    }
                    dataSource={this.props.currentRequests}
                    onChange={this.handleSortCurrent}
                  >
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('brand')}
                        title="Brand"
                        dataIndex="brand"
                        key="brand"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('model')}
                        title="Model"
                        dataIndex="model"
                        key="model"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('jobNumber')}
                        title="Rebuild Job"
                        dataIndex="jobNumber"
                        key="jobNumber"
                      />
                    ) : null}
                    <Column
                      {...this.getColumnSearchProps('description')}
                      title="Description"
                      dataIndex="description"
                      key="description"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('serial')}
                        title="Serial No."
                        dataIndex="serial"
                        key="serial"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('type')}
                        title="Type"
                        dataIndex="type"
                        key="type"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('site')}
                        title="Site"
                        dataIndex="site"
                        key="site"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('requisitionNumber')}
                        title="Supply Job"
                        dataIndex="requisitionNumber"
                        key="requisitionNumber"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('timestamp')}
                        title="Request Timestamp"
                        dataIndex="timestamp"
                        key="timestamp"
                      />
                    ) : null}
                    <Column
                      title="Actions"
                      render={(text, request) => (
                        <span>
                          <span>
                            <a
                              data={request._id}
                              onClick={e => this.onRequestApprove(request)}
                            >
                              Approve Request
                            </a>
                          </span>

                          <span>
                            <Divider type="vertical" />
                            <a
                              data={request._id}
                              onClick={e => this.onRequestReject(request)}
                            >
                              Reject Request
                            </a>
                          </span>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Previous" key="2">
              <Row type="flex" justify="start" align="middle">
                <Col span={24}>
                  <StyledTable
                    style={{ background: 'white' }}
                    rowKey="_id"
                    size="middle"
                    pagination={{
                      defaultPageSize: 25
                    }}
                    expandedRowRender={
                      window.innerWidth <= 420
                        ? record => (
                          <Card
                            size="small"
                            title={record.itemCode}
                            style={{ width: 300, marginLeft: -35 }}
                          >
                            <p>Brand: {record.brand}</p>
                            <p>Model: {record.model}</p>
                            <p>Rebuild Job: {record.jobNumber}</p>
                            <p>Description: {record.description}</p>
                            <p>Serial: {record.serial}</p>
                            <p>Type: {record.type}</p>
                            <p>Site: {record.site}</p>
                            <p>Supply Job: {record.requisitionNumber}</p>
                          </Card>
                        )
                        : null
                    }
                    dataSource={this.props.previousRequests}
                    onChange={this.handleSortPrevious}
                  >
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('brand')}
                        title="Brand"
                        dataIndex="brand"
                        key="brand"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('model')}
                        title="Model"
                        dataIndex="model"
                        key="model"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('jobNumber')}
                        title="Rebuild Job"
                        dataIndex="jobNumber"
                        key="jobNumber"
                      />
                    ) : null}
                    <Column
                      {...this.getColumnSearchProps('description')}
                      title="Description"
                      dataIndex="description"
                      key="description"
                    />
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('serial')}
                        title="Serial No."
                        dataIndex="serial"
                        key="serial"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('type')}
                        title="Type"
                        dataIndex="type"
                        key="type"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('site')}
                        title="Site"
                        dataIndex="site"
                        key="site"
                      />
                    ) : null}
                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('requisitionNumber')}
                        title="Supply Job"
                        dataIndex="requisitionNumber"
                        key="requisitionNumber"
                      />
                    ) : null}

                    {window.innerWidth > 420 ? (
                      <Column
                        {...this.getColumnSearchProps('timestamp')}
                        title="Request Timestamp"
                        dataIndex="timestamp"
                        key="timestamp"
                      />
                    ) : null}
                    <Column
                      title="Actions"
                      render={(text, pexunit) => (
                        <span>
                          <a onClick={e => this.onExportPdf(pexunit)}>
                            Export PDF
                          </a>
                          <Divider type="vertical" />
                          <a
                            onClick={e => this.onEditRequestOutVisible(pexunit)}
                          >
                            Edit
                          </a>
                          <Divider type="vertical" />
                          <a
                            onClick={e => {
                              this.setState({
                                deleteVisible: true,
                                deleteRequest: pexunit
                              });
                            }}
                          >
                            Delete
                          </a>
                        </span>
                      )}
                    />
                  </StyledTable>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Spin>
        <Modal
          title={'Delete Request'}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteRequest}
          onCancel={this.cancelDelete}
          okText="Accept"
          cancelText="Cancel"
        >
          <span>Are you sure you want to delete this request?</span>
        </Modal>
        <Modal
          title={'Edit Request'}
          visible={this.state.outVisible}
          onOk={this.onEditRequestOut}
          onCancel={e => this.setState({ outVisible: false })}
          okText="Submit"
          cancelText="Cancel"
        >
          <span>Supply Job</span>
          <Input
            style={{ marginBottom: 15, width: '100%' }}
            value={this.state.request.requisitionNumber}
            onChange={e =>
              this.setState({
                request: {
                  ...this.state.request,
                  requisitionNumber: e.target.value
                }
              })
            }
          />
          <span>Site</span>
          <Input
            style={{ marginBottom: 15, width: '100%' }}
            value={this.state.request.site}
            onChange={e =>
              this.setState({
                request: { ...this.state.request, site: e.target.value }
              })
            }
          />
          {/* <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: '100%', marginBottom: 14 }}
            onSearch={this.handleSearchTechnician}
            value={this.state.request.userName}
            placeholder={'Type in at least 4 characters to start search'}
            onChange={e => this.setState({request:{...this.state.request, userName: e }})}
          >
            {technicianOptions}
          </Select> */}
          <span>Rebuild Job</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: '100%' }}
            onSearch={this.handleSearchJob}
            value={this.state.request.jobNumber}
            placeholder={'Type in at least 4 characters to start search'}
            onChange={e =>
              this.setState({
                request: { ...this.state.request, jobNumber: e }
              })
            }
          >
            {jobOptions}
          </Select>
        </Modal>
      </div>
    );
  }
}

export default Request;
