import React from 'react';
import styled from 'styled-components';
import { SettingFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Menu, Select, Row, Col, Spin, Input, List, Switch } from 'antd';
import { Layout } from 'antd';
import Resizer from 'react-image-file-resizer';
import { ButtonSquare } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
const { Content, Sider } = Layout;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const FormItem = Form.Item;
const Option = Select.Option;
const Account = props => {
  return (
    <Form onSubmit={props.handleAccountInfoSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>First Name</span>
            }
          >
            {props.props.form.getFieldDecorator('name', {
              rules: [{ required: true, message: 'Name required' }],
              initialValue: props.props.user.name
            })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>Surname</span>
            }
          >
            {props.props.form.getFieldDecorator('surname', {
              rules: [{ required: true, message: 'Surname required' }],
              initialValue: props.props.user.surname
            })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>Nickname</span>
            }
          >
            {props.props.form.getFieldDecorator('nickname', {
              rules: [{ required: false }],
              initialValue: props.props.user.nickname
            })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>Number</span>
            }
          >
            {props.props.form.getFieldDecorator('number', {
              rules: [{ required: true, message: 'Number required' }],
              initialValue: props.props.user.number
            })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Alternative Number
              </span>
            }
          >
            {props.props.form.getFieldDecorator('altNumber', {
              rules: [{ required: false }],
              initialValue: props.props.user.altNumber
            })(<Input style={{ borderColor: '#BDB2B2', height: 45 }} />)}
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Where did you hear about us?
              </span>
            }
          >
            {props.props.form.getFieldDecorator('heardFrom', {
              rules: [{ required: false }],
              initialValue: props.props.user.heardFrom
            })(
              <Selection
                showSearch
                style={{ width: '100%' }}
                placeholder="Select a place"
                optionFilterProp="children"
              >
                <Option key={'Radio'} value={'Radio'}>
                  {'Radio'}
                </Option>
                <Option key={'TV'} value={'TV'}>
                  {'TV'}
                </Option>
                <Option key={'Facebook'} value={'Facebook'}>
                  {'Facebook'}
                </Option>
                <Option key={'Twitter'} value={'Twitter'}>
                  {'Twitter'}
                </Option>
                <Option key={'Instagram'} value={'Instagram'}>
                  {'Instagram'}
                </Option>
                <Option
                  key={'Billboard or Streetpole ad'}
                  value={'Billboard or Streetpole ad'}
                >
                  {'Billboard or Streetpole ad'}
                </Option>
                <Option key={'Word of mouth'} value={'Word of mouth'}>
                  {'Word of mouth'}
                </Option>
                <Option key={'Google'} value={'Google'}>
                  {'Google'}
                </Option>
              </Selection>
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};
const Password = props => {
  return (
    <Form onSubmit={props.handlePasswordChange}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('newPass', {
              rules: [
                { required: true, message: 'New password required' },
                { validator: props.validateToNextPassword }
              ]
            })(
              <Input.Password
                onBlur={props.handleConfirmBlur}
                type="password"
                style={{ borderColor: '#BDB2B2', height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Confirm New Password
              </span>
            }
            hasFeedback
          >
            {props.props.form.getFieldDecorator('confirmPass', {
              rules: [
                { required: true, message: 'Confirm new password' },
                { validator: props.compareToFirstPassword }
              ]
            })(
              <Input.Password
                type="password"
                style={{ borderColor: '#BDB2B2', height: 45 }}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <FormItem>
          <ButtonSquare
            style={{ height: 45 }}
            className="submit-button"
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </ButtonSquare>
        </FormItem>
      </Row>
    </Form>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 2 }
  }
};
const Notification = props => {
  return (
    <Form {...formItemLayout} onSubmit={props.handleNotificationSave}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me when my car gets a bid
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me when troef-die-skaal reviews my stock
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <FormItem
            label={
              <span style={{ color: '#6D6E70', fontSize: 16 }}>
                Notify me of troef-die-skaal updates
              </span>
            }
            hasFeedback
          >
            <Switch defaultChecked />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: this.props.user.image,
      activeView: 'account'
    };
  }
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPass')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPass'], { force: true });
    }
    callback();
  };
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleAccountInfoSave = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.image = this.state.image;
        this.props
          .saveAccountInfo(values)
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Success',
              'Successfully saved account information'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };
  handlePasswordChange = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            this.setState({ loading: false });
            Notify('success', 'Success', 'Successfully changed password');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', window.i18n.translate('Notify3'), e);
          });
      }
    });
  };
  handleProfileImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        image: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 200, 200, 'PNG', 100, 0, uri => {
      callback(uri);
    });
  };
  removeImage = e => {
    this.setState({
      image: undefined
    });
  };
  render() {
    return (
      <div>
        <Row type="flex" justify="start" align="middle">
          <Col span={12} style={{ marginBottom: 20 }}>
            <SettingFilled
              style={{ fontSize: 25, color: '#EC8144', marginRight: 20 }}
            />
            <span style={{ fontWeight: 750, fontSize: 25 }}>
              Account Settings
            </span>
          </Col>
        </Row>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="start" align="middle">
            <Col span={24}>
              <Layout>
                <Layout>
                  <Sider
                    trigger={null}
                    width={160}
                    style={{
                      zIndex: 9,
                      background: 'white',
                      height: '100vh',
                      left: 0,
                      borderTopLeftRadius: 10,
                      borderRight: '1px solid #E5E5E5'
                    }}
                  >
                    <Menu
                      onClick={e => {
                        this.setState({ activeView: e.key });
                      }}
                      style={{ background: 'none', border: 0 }}
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={['account']}
                    >
                      <Menu.Item key="account">
                        <span style={{ fontSize: 16 }}>Account</span>
                      </Menu.Item>
                      <Menu.Item key="password">
                        <span style={{ fontSize: 16 }}>Password</span>
                      </Menu.Item>
                      <Menu.Item key="notification">
                        <span style={{ fontSize: 16 }}>Notification</span>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Layout>
                    <Content
                      style={{
                        background: 'white',
                        padding: 40,
                        borderTopRightRadius: 10
                      }}
                    >
                      {this.state.activeView === 'account' ? (
                        <Account
                          props={this.props}
                          image={this.state.image}
                          beforeUpload={this.beforeUpload}
                          handleImageUpload={this.handleImageUpload}
                          handleProfileImage={this.handleProfileImage}
                          handleAccountInfoSave={this.handleAccountInfoSave}
                          removeImage={this.removeImage}
                        />
                      ) : this.state.activeView === 'password' ? (
                        <Password
                          props={this.props}
                          handlePasswordChange={this.handlePasswordChange}
                          compareToFirstPassword={this.compareToFirstPassword}
                          validateToNextPassword={this.validateToNextPassword}
                          handleConfirmBlur={this.handleConfirmBlur}
                        />
                      ) : this.state.activeView === 'notification' ? (
                        <Notification
                          props={this.props}
                          image={this.state.image}
                          beforeUpload={this.beforeUpload}
                          handleImageUpload={this.handleImageUpload}
                          handleProfileImage={this.handleProfileImage}
                          handleAccountInfo={this.handleAccountInfo}
                        />
                      ) : null}
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}
const WrappedAccountSettings = Form.create()(AccountSettings);
export default WrappedAccountSettings;
