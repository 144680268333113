import React from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  Upload,
  Checkbox,
  Space,
  InputNumber,
  Switch,
  Tooltip
} from 'antd';
import {
  CloseCircleFilled,
  CheckOutlined,
  CloseOutlined,
  BellOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
  PlusOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import PerpPlaceholder from 'src/static/perp-placeholder.png';
import { GiIceSkate } from 'react-icons/gi';
import { ButtonSquare } from 'src/components/elements/Button';
import dropImage1 from 'src/static/icons/carImageUpload/safsa-img.jpg';
import dropImage2 from 'src/static/icons/carImageUpload/placeholder.png';
import dropImage from 'src/static/icons/carImageUpload/gnfsa-icon.png';
import Resizer from 'react-image-file-resizer';
import { Form } from '@ant-design/compatible';
import Notify from 'src/components/meta/Notification';
import Highlighter from 'react-highlight-words';
import 'braft-editor/dist/index.css';
import BraftEditor from 'braft-editor';
import Editor from 'react-quill-antd';
import 'react-quill-antd/dist/index.css';
import ReactPlayer from 'react-player'

const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;

const { Option } = Select;
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledCard = styled(Card)`
.ant-card-body {
  padding: 5px !important;
}
`;
const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    // ['link', 'image', 'video'],
    ['link'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when Hunting1ing HTML:
    matchVisual: true
  }
};
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: #8898aa !important;
    background: #f1f1f1 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    font-weight: 500;
    color: #525f7f;
    font-size: 15px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: '';
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 47px !important;
  border-right-width: 0px !important;
    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;
    border-right-width: 0px !important;
    box-shadow:none !important;
}
  :active{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    border-right-width: 0px !important;
     box-shadow:none !important;
   
  }
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const StyledModal = styled(Modal)`
  .ant-modal-body {
    height: 95vh !important;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey;
  border-radius: 0em !important;
  height: 47px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
function callback(key) {
  console.log(key);
}
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class AddStockForm extends React.Component {
  state = {
    manualInput: this.props.editStock
      ? this.props.editStock.manualInput
      : false,
    previewVisible: false,
    yocoVisible: false,
    formVisible: false,
    eftVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    stockNumbers: [],
    years: [],
    makes: [],
    variants: [],
    models: [],
    mileages: [],
    retailIncs: [],
    intColours: [],
    extColours: [],
    capacityCCs: [],
    bodyTypes: [],
    engineSizes: [],
    unladenWeights: [],
    vehicleDrives: [],
    axles: [],
    doors: [],
    cylinders: [],
    passengers: [],
    vehicleStockNumbers: [],
    vehicleStockNumber: undefined,
    intColour: undefined,
    extColour: undefined,
    passenger: undefined,
    retailPriceIncl: undefined,
    cylinder: undefined,
    unladenWeight: undefined,
    axle: undefined,
    vehicleDrive: undefined,
    engineSize: undefined,
    bodyType: undefined,
    capacityCC: undefined,
    stockNumber: undefined,
    modelYear: undefined,
    make: undefined,
    mileage: undefined,
    series: undefined,
    branchImage: undefined,
    frontLeftImage: undefined,
    frontImage: undefined,
    frontRightImage: undefined,
    rightSideImage: undefined,
    wheelsImage: undefined,
    backRightImage: undefined,
    backImage: undefined,
    backLeftImage: undefined,
    leftSideImage: undefined,
    bootImage: undefined,
    frontSeatImage: undefined,
    backSeatImage: undefined,
    dashPassengerImage: undefined,
    dashDriverImage: undefined,
    dashDriverSideImage: undefined,
    radioImage: undefined,
    radioCloseImage: undefined,
    gearsImage: undefined,
    steeringImage: undefined,
    spareKeyImage: undefined,
    curDT: new Date().toLocaleString()
  };

  handleCancel = () => this.setState({ previewVisible: false });
  handleCancelEft = () => this.setState({ eftVisible: false });
  handleCancelYoco = () => this.setState({ yocoVisible: false });
  handleCancelForm = () => this.setState({ formVisible: false });
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };
  handleChange = ({ fileList }) => this.setState({ fileList });
  selectManual = checked => {
    this.setState({
      manualInput: !this.state.manualInput
    });
  };

  componentWillMount = () => {
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER + 'troef-die-skaal/api/evolve/findstocknumbers',
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving stock numbers'
        });
      const result = await res.json();
      this.setState({ stockNumbers: result.stockNumbers });
    });
    fetch(
      process.env.REACT_APP_GLOBAL_SERVER + 'troef-die-skaal/api/evolve/findpassengers',
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    ).then(async res => {
      if (!res.ok)
        Notify.error({
          message: 'Error retrieving stock numbers'
        });
      const result = await res.json();
      this.setState({ passengers: result.passengers });
    });
    if (this.props.editStock) {
      this.setState({
        branchImage: this.props.editStock.branchImage,
        frontLeftImage: this.props.editStock.frontLeftImage,
        frontImage: this.props.editStock.frontImage,
        frontRightImage: this.props.editStock.frontRightImage,
        rightSideImage: this.props.editStock.rightSideImage,
        wheelsImage: this.props.editStock.wheelsImage,
        backRightImage: this.props.editStock.backRightImage,
        backImage: this.props.editStock.backImage,
        backLeftImage: this.props.editStock.backLeftImage,
        leftSideImage: this.props.editStock.leftSideImage,
        bootImage: this.props.editStock.bootImage,
        frontSeatImage: this.props.editStock.frontSeatImage,
        backSeatImage: this.props.editStock.backSeatImage,
        dashPassengerImage: this.props.editStock.dashPassengerImage,
        dashDriverImage: this.props.editStock.dashDriverImage,
        dashDriverSideImage: this.props.editStock.dashDriverSideImage,
        radioImage: this.props.editStock.radioImage,
        radioCloseImage: this.props.editStock.radioCloseImage,
        gearsImage: this.props.editStock.gearsImage,
        steeringImage: this.props.editStock.steeringImage,
        spareKeyImage: this.props.editStock.spareKeyImage
      });
    }
  };
  handleBranchImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImage: imageUrl
      });
    });
  };
  handleFrontLeftImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontLeftImage: imageUrl
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl
      });
    });
  };
  handleFrontRightImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontRightImage: imageUrl
      });
    });
  };
  handleRightSideImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightSideImage: imageUrl
      });
    });
  };
  handleRadioCloseImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioCloseImage: imageUrl
      });
    });
  };
  handleWheelsImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        wheelsImage: imageUrl
      });
    });
  };
  handleBackRightImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backRightImage: imageUrl
      });
    });
  };
  handleBackImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backImage: imageUrl
      });
    });
  };
  handleBackLeftImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backLeftImage: imageUrl
      });
    });
  };
  handleLeftSideImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        leftSideImage: imageUrl
      });
    });
  };
  handleBootImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        bootImage: imageUrl
      });
    });
  };
  handleFrontSeatImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontSeatImage: imageUrl
      });
    });
  };
  handleBackSeatImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        backSeatImage: imageUrl
      });
    });
  };
  handleDashPassengerImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashPassengerImage: imageUrl
      });
    });
  };
  handleDashDriverImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashDriverImage: imageUrl
      });
    });
  };
  handleDashDriverSideImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        dashDriverSideImage: imageUrl
      });
    });
  };
  handleRadioImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioImage: imageUrl
      });
    });
  };
  handleRadioCloseImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        radioCloseImage: imageUrl
      });
    });
  };
  handleGearsImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        gearsImage: imageUrl
      });
    });
  };
  handleSteeringImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        steeringImage: imageUrl
      });
    });
  };
  handleSpareKeyImage = info => {
    this.setState({
      loading: true
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        spareKeyImage: imageUrl
      });
    });
  };
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify('error', window.i18n.translate('ImageSmaller'));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, 'JPG', 100, 0, uri => {
      callback(uri);
    });
  };

  render() {
    const controls = [
      'bold',
      'italic',
      'underline',
      'text-color',
      'separator',
      'link',
      'separator',
      'media'
    ];
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Form layout="vertical">
        <Row>
          {/* <Col span={24} style={{ textAlign: window.isPhone ? 'center' : '' }}>
            <h1 style={{ fontSize: '30px', fontWeight: 700, float: 'left' }}>
              Proudly troef-die-skaal
            </h1>
            {window.isPhone ? (
              <div>
                <Button
                  onClick={e => this.setState({ yocoVisible: true })}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '3em',
                    marginRight: 10,
                    marginLeft: 0
                  }}
                >
                  Pay Now via Yoco
                </Button>
                <br />
                <span style={{ paddingTop: '0.4em' }}>
                  {'  '} or {'  '}
                </span>
                <br />
                <Button
                  onClick={e => this.setState({ eftVisible: true })}
                  style={{
                    backgroundColor: 'transparent',
                    color: '#53B6F8',
                    borderRadius: '3em',
                    border: '0px solid #53B6F8',

                    paddingRight: 6
                  }}
                >
                  EFT via listed Banking Details
                </Button>
              </div>
            ) : (
              <span>
                <Button
                  onClick={e => this.setState({ yocoVisible: true })}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '3em',
                    float: 'right',
                    marginLeft: 15
                  }}
                >
                  Pay Now via Yoco
                </Button>
                <span style={{ paddingTop: '0.4em', float: 'right' }}>
                  {'  '} or {'  '}
                </span>
                <Button
                  onClick={e => this.setState({ eftVisible: true })}
                  style={{
                    backgroundColor: 'transparent',
                    color: '#53B6F8',
                    borderRadius: '3em',
                    border: '0px solid #53B6F8',
                    float: 'right',
                    paddingLeft: 6
                  }}
                >
                  EFT via listed Banking Details
                </Button>
              </span>
            )}
          </Col> */}
          <Col span={24}>
            <h1
              style={{
                marginTop: '1em',
                fontSize: window.isPhone ? '20px' : '27px',
                fontWeight: 700,
                textAlign: window.isPhone ? 'center' : ''
              }}
            >
              {' '}
              {this.props.editStock.eventTitle}{' '}
            </h1>
          </Col>
          {/* <Col span={24}>
            <Button
              onClick={e => this.setState({ formVisible: true })}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '3em',
                marginRight: 10,
                // marginTop: "1em",
                marginBottom: '1em',
                marginLeft: 0
              }}
            >
              ENTER NOW
            </Button>
          </Col> */}
        </Row>
        {/* <Tooltip placement="left" title="Enter Now">
          <Button
            shape="round"
            onClick={e => this.setState({ formVisible: true })}
            style={{
              position: 'fixed',
              bottom: '6.5em',
              right: '1em',
              fontSize: '14px',
              width: '4em',
              height: '4em',
              color: 'white',
              backgroundColor: 'black',
              //height: "3em",
              marginLeft: '0.7em'
            }}
          >
            <GiIceSkate />
          </Button>
        </Tooltip> */}
        <Row
          style={{
            textAlighn: 'center',
            padding: window.isPhone ? '' : '2em',
            margin: 'auto',
            height: '100%'
          }}
        >
          <Col span={24}>
            <ReactPlayer style={{ minHeight: "30em" }} playing={true} controls light={
              this.state.frontImage ?
                process.env.REACT_APP_GLOBAL_SERVER +
                'troef-die-skaal/api/images/' +
                this.state.frontImage
                : PerpPlaceholder
            } width='100%' height='100%' url={this.props.editStock.entryLink} />

          </Col>
        </Row>
        {/* <Row type="flex" justify="space-between" align="middle">
          <Col span={2} />
          <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                // border: '1px solid black',
                marginBottom: 10
              }}
              cover={
                this.state.frontLeftImage ? (
                  <img
                    style={{
                      width: '20em',
                      height: '20em',
                      objectFit: 'contain'
                    }}
                    alt="example"
                    src={
                      this.state.frontLeftImage.search('data') > -1
                        ? this.state.frontLeftImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'troef-die-skaal/api/images/' +
                        this.state.frontLeftImage
                    }
                  />
                ) : (
                  <img
                    style={{
                      width: '20em',
                      height: '20em',
                      objectFit: 'contain'
                    }}
                    alt="example"
                    src={dropImage1}
                  />
                )
              }
            />
          </Col>
          <Col span={2} />
          <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                // border: '1px solid black',
                marginBottom: 15
              }}
              cover={
                this.state.frontImage ? (
                  <img
                    style={{
                      width: '20em',
                      height: '20em',
                      objectFit: 'contain'
                    }}
                    alt="example"
                    src={
                      this.state.frontImage.search('data') > -1
                        ? this.state.frontImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'troef-die-skaal/api/images/' +
                        this.state.frontImage
                    }
                  />
                ) : (
                  <img
                    style={{
                      width: '20em',
                      height: '20em',
                      objectFit: 'contain'
                    }}
                    alt="example"
                    src={dropImage2}
                  />
                )
              }
            />
          </Col>
          <Col span={2} />
          <Col span={6} style={{ padding: '0.5em' }}>
            <Cards
              style={{
                width: '100%',
                // border: '1px solid black',
                marginBottom: 15
              }}
              cover={
                this.state.frontRightImage ? (
                  <img
                    style={{
                      width: '20em',
                      height: '20em',
                      objectFit: 'contain'
                    }}
                    alt="example"
                    src={
                      this.state.frontRightImage.search('data') > -1
                        ? this.state.frontRightImage
                        : process.env.REACT_APP_GLOBAL_SERVER +
                        'troef-die-skaal/api/images/' +
                        this.state.frontRightImage
                    }
                  />
                ) : (
                  <img alt="example" src={dropImage} />
                )
              }
            />
          </Col>
        </Row> */}
        <Row>
          <Col span={24} style={{ marginTop: '3em' }}>
            <FormItem >
              {this.props.form.getFieldDecorator('eventContent', {
                rules: [
                  {
                    required: true,
                    message: 'Webinar Content required'
                  }
                ],

                initialValue: this.props.editStock
                  ? this.props.editStock.eventContent
                  : ""

              })(<Editor readOnly />)}
            </FormItem>
          </Col>

          {/* <Col
            span={24}
            style={{
              textAlign: window.isPhone ? 'center' : 'left',
              marginTop: '2em'
            }}
          >
            {window.isPhone ? (
              <div>
                <Button
                  onClick={e => this.setState({ yocoVisible: true })}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '3em',
                    marginRight: 10,
                    marginLeft: 0
                  }}
                >
                  Pay Now via Yoco
                </Button>
                <br />
                <span style={{ paddingTop: '0.4em' }}>
                  {'  '} or {'  '}
                </span>
                <br />
                <Button
                  onClick={e => this.setState({ eftVisible: true })}
                  style={{
                    backgroundColor: 'transparent',
                    color: '#53B6F8',
                    borderRadius: '3em',
                    border: '0px solid #53B6F8',

                    paddingRight: 6
                  }}
                >
                  EFT via listed Banking Details
                </Button>
              </div>
            ) : (
              <span>
                <Button
                  onClick={e => this.setState({ yocoVisible: true })}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '3em',
                    marginRight: 10,
                    marginLeft: 0
                  }}
                >
                  Pay Now via Yoco
                </Button>

                <span style={{ paddingTop: '0.4em' }}>
                  {'  '} or {'  '}
                </span>

                <Button
                  onClick={e => this.setState({ eftVisible: true })}
                  style={{
                    backgroundColor: 'transparent',
                    color: '#53B6F8',
                    borderRadius: '3em',
                    border: '0px solid #53B6F8',

                    paddingRight: 6
                  }}
                >
                  EFT via listed Banking Details
                </Button>
              </span>
            )}
          </Col> */}
        </Row>{' '}
        <StyledModal
          style={{
            top: 20,
            minWidth: '95vw',
            minHeight: '90vh'
          }}
          visible={this.state.formVisible}
          title={null}
          footer={null}
          onCancel={this.handleCancelForm}
        >
          <Row
            style={{
              textAlighn: 'center',
              padding: '2em',
              margin: 'auto',
              height: '100%'
            }}
          >
            <Col span={24}>
              <ReactPlayer controls light width='100%' height='100%' url={this.props.editStock.entryLink} />

            </Col>
          </Row>
        </StyledModal>{' '}
        <StyledModal
          style={{
            top: 20,
            minWidth: '95vw',
            minHeight: '90vh'
          }}
          visible={this.state.yocoVisible}
          title={null}
          footer={null}
          onCancel={this.handleCancelYoco}
        >
          <Row
            style={{
              textAlighn: 'center',
              padding: '2em',
              margin: 'auto',
              height: '100%'
            }}
          >
            <Col span={24}>
              <iframe
                style={{ width: '100%', height: '100%' }}
                src="https://pay.yoco.com/south-african-figure-skating-association-gauteng-north"
              />
            </Col>
          </Row>
        </StyledModal>{' '}
        <Modal
          visible={this.state.eftVisible}
          title={null}
          footer={null}
          onCancel={this.handleCancelEft}
        >
          <Row
            style={{
              textAlighn: 'center',
              padding: '2em',
              maxWidth: '40em',
              margin: 'auto',
              borderRadius: '1em',
              height: '40em',
              backgroundColor: '#F0F0F0'
            }}
          >
            <Col span={24}>
              <h2
                className="f-weight-600"
                style={{
                  fontSize: '25px',
                  fontWeight: 700,
                  marginBottom: '0em',
                  marginTop: '0em',
                  color: 'black',
                  textAlign: 'center'
                }}
              >
                BANK DETAILS:
              </h2>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginTop: '0em',
                  lineHeight: 1.2,
                  color: 'black'
                }}
              >
                BANK
                <br />
                <br />
                ACCOUNT NUMBER
                <br />
                <br />
                BRANCH CODE
                <br />
                <br />
                REFERENCE
              </p>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginTop: '0em',
                  lineHeight: 1.2,
                  color: 'black'
                }}
              >
                FNB
                <br />
                <br />
                6290 3190 122
                <br />
                <br />
                250655 <br />
                <br />
                Name, Surname & Event
                <br />
                <br />
              </p>
            </Col>
            <Col span={24}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 500,
                  marginTop: '0em',
                  lineHeight: 1.2,
                  color: 'black'
                }}
              >
                A POP must be sent to{' '}
                <a href="mailto:chair@gnfigureskating.co.za">
                  chair@gnfigureskating.co.za
                </a>{' '}
                and{' '}
                <a href="mailto:finance@gnfigureskating.co.za">
                  finance@gnfigureskating.co.za
                </a>{' '}
                This must be sent when entering. If there is no POP, the entry
                is considered incomplete.
              </p>
            </Col>
          </Row>
        </Modal>{' '}
        {/*<Row type='flex' justify='space-around' align='middle'>
          <Button type='primary' onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type='primary' onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>*/}
      </Form>
    );
  }
}
const StockForm = Form.create()(AddStockForm);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: '',
      editStock: undefined,
      size: 'large',
      flag: false,
      currentTab: 'All'
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllStock()
      .then(() => {
        let itemCodes = [];
        this.props.stock.forEach(stock => {
          if (stock.itemCode) {
            itemCodes.push(
              'Part: ' + stock.itemCode + ' - Location: ' + stock.location
            );
          }
        });
        this.setState({ loading: false, itemCodes: itemCodes });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllStock()
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  'Part: ' + stock.itemCode + ' - Location: ' + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };
  handleBack = () => {
    if (this.state.previewVisible) {
      this.setState({ previewVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  vehiclesForm;
  onAddStock = e => {
    this.setState({ previewVisible: true, editStock: undefined });
  };

  handleAddStock = e => {
    e.preventDefault();
    this.vehiclesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.branchImage = this.vehiclesForm.state.branchImage;
        values.frontLeftImage = this.vehiclesForm.state.frontLeftImage;
        values.frontImage = this.vehiclesForm.state.frontImage;
        values.frontRightImage = this.vehiclesForm.state.frontRightImage;
        values.rightSideImage = this.vehiclesForm.state.rightSideImage;
        values.wheelsImage = this.vehiclesForm.state.wheelsImage;
        values.backRightImage = this.vehiclesForm.state.backRightImage;
        values.backImage = this.vehiclesForm.state.backImage;
        values.backLeftImage = this.vehiclesForm.state.backLeftImage;
        values.leftSideImage = this.vehiclesForm.state.leftSideImage;
        values.bootImage = this.vehiclesForm.state.bootImage;
        values.frontSeatImage = this.vehiclesForm.state.frontSeatImage;
        values.backSeatImage = this.vehiclesForm.state.backSeatImage;
        values.dashPassengerImage = this.vehiclesForm.state.dashPassengerImage;
        values.dashDriverImage = this.vehiclesForm.state.dashDriverImage;
        values.dashDriverSideImage = this.vehiclesForm.state.dashDriverSideImage;
        values.radioImage = this.vehiclesForm.state.radioImage;
        values.radioCloseImage = this.vehiclesForm.state.radioCloseImage;
        values.gearsImage = this.vehiclesForm.state.gearsImage;
        values.steeringImage = this.vehiclesForm.state.steeringImage;
        values.spareKeyImage = this.vehiclesForm.state.spareKeyImage;
        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock()
              .then(() => {
                if (this.state.editStock) {
                  Notify('success', 'Successfully saved event');
                } else {
                  Notify('success', 'Successfully added event');
                }
                this.vehiclesForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  previewVisible: false,
                  editStock: undefined,
                  currentTab: 'All'
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };

  handleEditStock = e => {
    this.setState({ previewVisible: true, editStock: e });
  };

  handleDeleteStock = e => {
    const id = e.currentTarget.getAttribute('data');
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this event?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteStock(id)
          .then(() => {
            that.props
              .findAllStock()
              .then(() => {
                that.setState({ loading: false, currentTab: 'All' });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify('success', 'Successfully deleted event');
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify('error', e);
          });
      },
      oncancel() { }
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      sortedInfo: sorter
    });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? 'black' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });

  renderCondition = (condition, color) => {
    return (
      <p style={{ margin: 0 }}>
        {/* <StyledBadge color={color} style={{}} /> */}
        {condition}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };

  render() {
    let descriptions = this.props.stock.map(vehicle => {
      return { value: vehicle.description };
    });
    const { condition } = this.state;
    const { stock } = this.props;
    let vehicles;
    if (this.state.currentTab === 'All') {
      vehicles = stock;
    } else {
      vehicles = stock.filter(
        vehicle => vehicle.condition === this.state.currentTab
      );
    }
    const dataSource = [
      {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street'
      },
      {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street'
      }
    ];

    const columns = [
      {
        title: 'Year',
        dataIndex: 'modelYear',
        key: 'modelYear'
      },
      {
        title: 'Make',
        dataIndex: 'make',
        key: 'make'
      },
      {
        title: 'Model',
        dataIndex: 'series',
        key: 'series'
      },
      {
        title: 'Derivative',
        dataIndex: 'modelDesc',
        key: 'modelDesc'
      },
      {
        title: 'Condition',
        dataIndex: 'condition',
        //key: "condition",
        render: condition => {
          switch (condition) {
            case 'Nestling':
              return this.renderCondition('Nestling', '#4BED5B');
            case 'Learning to feed':
              return this.renderCondition('Learning to feed', 'lightgrey');
            case 'Hunting':
              return this.renderCondition('Hunting', 'blue');
            case 'Flying High':
              return this.renderCondition('Flying High', 'blue');
            default:

              return this.renderCondition('None', 'lightgrey');
          }
        }
      },
      {
        title: 'Price',
        dataIndex: 'price',
        //key: "price",
        render: price => (
          <StyledInputNumber
            bordered={false}
            defaultValue={price}
            disabled
            formatter={price =>
              `R ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={price => price.replace(/\R\s?|(,*)/g, '')}
          />
        )
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (text, stock) => (
          <span>
            <Button
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '3em'
              }}
              onClick={e => this.handleEditStock(stock)}
            >
              View
            </Button>
            {/* <Divider type="vertical" />
            <DeleteFilled
              style={{
                backgroundColor: '#CFD5E3',
                color: 'white',
                padding: '0.5em',
                borderRadius: '3em'
              }}
              data={stock._id}
              onClick={this.handleDeleteStock}
            /> */}
          </span>
        )
      }
    ];
    return (
      <div style={{ maxWidth: '90em', margin: 'auto' }}>
        <Spin spinning={this.state.loading}>
          {this.state.previewVisible ? (
            <StyledCard
              bordered={false}
              style={{
                margin: 'auto',
                marginTop: '3em',
                backgroundColor: 'white',
                borderRadius: '0.8em',
                boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.08)'
              }}
            >
              <div
                style={{
                  padding: window.isPhone ? '' : '3em',
                  paddingTop: '1em',
                  paddingBottom: '4em',
                  margin: 'auto'
                }}
              >
                <StockForm
                  descriptions={descriptions}
                  editStock={this.state.editStock}
                  wrappedComponentRef={form => (this.vehiclesForm = form)}
                />
                {/* <Tooltip placement="left" title="Save Vehicle">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.handleAddStock}
                    size="large"
                    style={{
                      position: 'fixed',
                      bottom: '6.5em',
                      right: '1em',
                      fontSize: '14px',
                      width: '4em',
                      height: '4em',
                      //height: "3em",
                      marginLeft: '0.7em'
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip> */}

                <Tooltip placement="left" title="Back">
                  <Button
                    shape="round"
                    onClick={e => this.setState({ previewVisible: false })}
                    style={{
                      fontSize: '14px',
                      width: '4em',
                      height: '4em',
                      position: 'fixed',
                      bottom: '2em',
                      right: '1em',
                      border: '1px solid #EEEFF0',
                      backgroundColor: 'lightgrey'
                    }}
                  >
                    <ArrowLeftOutlined />
                  </Button>
                </Tooltip>
              </div>
            </StyledCard>
          ) : (
            <Row>
              <Col span={24}>
                <StyledTabs
                  style={{ marginTop: '1em' }}
                  defaultActiveKey="1"
                  onChange={this.changeTypeTab}
                // tabBarExtraContent={{
                //   left: (
                //     <Button
                //       type="primary"
                //       shape="round"
                //       onClick={this.onAddStock}
                //       icon={
                //         <PlusCircleOutlined style={{ fontSize: '110%' }} />
                //       }
                //       size="large"
                //       style={{
                //         marginRight: '2.5em',
                //         height: '3em',
                //         width: '9em'
                //       }}
                //     >
                //       Add Event
                //     </Button>
                //   ),

                //   right: (
                //     <h1 style={{ color: '#656565', fontSize: 22 }}>
                //       Total Stock:
                //       <span style={{ color: 'black', fontSize: 25 }}>
                //         {this.props.stock.length}
                //       </span>
                //     </h1>
                //   )
                // }}
                >
                  <TabPane tab="All" key="All">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'lightgrey');
                              case 'Hunting':
                                return this.renderCondition('Hunting', 'blue');
                              case 'Flying High':
                                return this.renderCondition('Flying High', 'blue');
                              default:
                                return this.renderCondition('None', 'lightgrey');
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                            {/* <Divider type="vertical" /> */}
                            {/* <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="Nestling" key="Nestling">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'lightgrey');
                              case 'Hunting':
                                return this.renderCondition('Hunting', 'blue');
                              case 'Flying High':
                                return this.renderCondition('Flying High', 'blue');
                              default:
                                return this.renderCondition('None', 'lightgrey');
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                            {/* <Divider type="vertical" /> */}
                            {/* <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="Learning to feed" key="Learning to feed">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'lightgrey');
                              case 'Hunting':
                                return this.renderCondition('Hunting', 'blue');
                              case 'Flying High':
                                return this.renderCondition('Flying High', 'blue');
                              default:
                                return this.renderCondition('None', 'lightgrey');
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                            {/* <Divider type="vertical" /> */}
                            {/* <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="Hunting" key="Hunting">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'lightgrey');
                              case 'Hunting':
                                return this.renderCondition('Hunting', 'blue');
                              case 'Flying High':
                                return this.renderCondition('Flying High', 'blue');
                              default:
                                return this.renderCondition('None', 'lightgrey');
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                            {/* <Divider type="vertical" /> */}
                            {/* <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  <TabPane tab="Flying High" key="Flying High">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'lightgrey');
                              case 'Hunting':
                                return this.renderCondition('Hunting', 'blue');
                              case 'Flying High':
                                return this.renderCondition('Flying High', 'blue');
                              default:
                                return this.renderCondition('None', 'lightgrey');
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                            {/* <Divider type="vertical" /> */}
                            {/* <DeleteFilled
                              style={{
                                backgroundColor: '#CFD5E3',
                                color: 'white',
                                padding: '0.5em',
                                borderRadius: '3em'
                              }}
                              data={stock._id}
                              onClick={this.handleDeleteStock}
                            /> */}
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane>
                  {/* <TabPane tab="Demo" key="Demo">
                    <StyledTable
                      bordered={false}
                      dataSource={vehicles}
                      rowKey="_id"
                      size="middle"
                      pagination={{
                        defaultPageSize: 25
                      }}
                      style={{
                        minWidth: '15em',
                        marginTop: '0.5em'
                      }}
                    >
                      <Column
                        {...this.getColumnSearchProps('eventTitle')}
                        title="Title"
                        dataIndex="eventTitle"
                        key="eventTitle"
                      />

                      <Column
                        {...this.getColumnSearchProps('timeDate')}
                        title="Time Stamp"
                        dataIndex="timeDate"
                        key="timeDate"
                      />
                      {window.innerWidth > 420 ? (
                        <Column
                          {...this.getColumnSearchProps('condition')}
                          title="Category"
                          dataIndex="condition"
                          //key: "condition",
                          render={condition => {
                            switch (condition) {
                              case 'Nestling':
                                return this.renderCondition(
                                  'Nestling',
                                  '#4BED5B'
                                );
                              case 'Hunting':
                                return this.renderCondition(
                                  'Hunting',
                                  'lightgrey'
                                );
                              case 'Learning to feed':
                                return this.renderCondition('Learning to feed', 'blue');
                              default:
                                return this.renderCondition(
                                  'None',
                                  'lightgrey'
                                );
                            }
                          }}
                        />
                      ) : null}
                      <Column
                        //{...this.getColumnSearchProps("actions")}
                        title="Actions"
                        render={(text, stock) => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '3em'
                              }}
                              onClick={e => this.handleEditStock(stock)}
                            >
                              View
                            </Button>
                
                          </span>
                        )}
                      />
                    </StyledTable>
                  </TabPane> */}
                </StyledTabs>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedStock = Form.create()(Stock);
export default WrappedStock;
