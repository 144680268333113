import React from 'react';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Switch,
  Upload
} from 'antd';
import { getToken } from 'src/redux/selectors';
import moment from 'moment';
import Notify from 'src/components/meta/Notification';
import noImage from 'src/static/icons/carImageUpload/no-image-available.svg';
import Countdown from 'react-countdown-now';
import Highlighter from 'react-highlight-words';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const numeral = require('numeral');
const { TabPane } = Tabs;
const hammer = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6475 17.3418H1.65195C0.740018 17.3418 0 18.0817 0 18.9938V21.9977H14.2994V18.9938C14.2994 18.0817 13.5595 17.3418 12.6475 17.3418Z"
      fill="#AD5E00"
    />
    <path
      d="M21.7036 17.5744L11.6665 7.53725L13.6051 5.60203C13.9248 5.88391 14.4163 5.87013 14.7188 5.56766C15.0351 5.248 15.0351 4.7358 14.7188 4.41614L10.539 0.239743C10.2228 -0.0799143 9.70715 -0.0799143 9.39094 0.239743C9.07128 0.555998 9.07128 1.0716 9.39094 1.38781L9.35657 1.35344L4.04924 6.65728L4.08361 6.69505C3.76736 6.37539 3.25175 6.37539 2.93554 6.69505C2.61589 7.01131 2.61589 7.52691 2.93554 7.84317L7.11194 12.023C7.4316 12.3392 7.9472 12.3392 8.26346 12.023C8.57971 11.7033 8.57971 11.1912 8.26346 10.8715L8.29783 10.9058L10.2365 8.9706L20.2702 19.0043C20.6655 19.3996 21.3083 19.3996 21.7036 19.0043C22.0989 18.609 22.0989 17.9697 21.7036 17.5744Z"
      fill="#AD5E00"
    />
  </svg>
);

const StyledTable = styled(Table)`
  // .ant-table-thead > tr > th {
  //   text-align: center !important;

  // }
`;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`
            }
          ],
          initialValue: record[dataIndex]
        })(
          <Input
            placeholder={title + '...'}
            ref={node => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {!children[2] && !record.addButton ? (
          <span style={{ opacity: 0.7 }}>Click to edit...</span>
        ) : (
          children
        )}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class RequisitionFormClass extends React.Component {
  editRequisition;
  state = {
    parts: []
  };
  componentWillMount = () => {
    if (this.props.editRequisition) {
      this.editRequisition = JSON.parse(
        JSON.stringify(this.props.editRequisition)
      );
      let parts = this.editRequisition.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>
      });
      this.setState({ parts: parts });
    } else {
      let parts = this.state.parts;
      parts.push({
        addButton: <Button onClick={this.addPart}>Add Part</Button>
      });
      this.setState({ parts: parts });
    }
  };
  componentWillUnmount = () => {
    this.editRequisition = undefined;
  };
  barcodeScanner = () => {
    window.cordova.plugins.barcodeScanner.scan(
      result => {
        this.props.form.setFieldsValue({
          itemCode: result.text
        });
      },
      error => {
        alert('Scanning failed: ' + error);
      },
      {
        preferFrontCamera: true,
        showFlipCameraButton: true,
        showTorchButton: true,
        prompt: 'Place a barcode inside the scan area'
      }
    );
  };
  addPart = () => {
    let parts = this.state.parts;
    parts.splice(parts.length - 1, 0, {
      key: parts.length - 1,
      itemCode: undefined,
      qty: undefined,
      description: undefined,
      groupName: undefined
    });
    this.setState({ parts: parts });
  };
  deletePart = part => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => part.key === item.key);
    parts.splice(index, 1);
    this.setState({ parts: parts });
  };
  handleSave = row => {
    let parts = this.state.parts;
    const index = parts.findIndex(item => row.key === item.key);
    const item = parts[index];
    parts.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ parts: parts });
  };

  handleXMLUpload = ({ onSuccess, file }) => {
    const parser = require('fast-xml-parser');
    const reader = new FileReader();
    reader.addEventListener('load', event => {
      let jsonObj = parser.parse(event.target.result);
      if (jsonObj.PARTSLIST) {
        let parts = this.state.parts;
        parts.pop();
        jsonObj.PARTSLIST.PARTS.PART.forEach(part => {
          parts.push({
            key: parts.length - 1,
            itemCode: part.PARTNO,
            qty: part.QUANTITY,
            description: part.PARTNAME,
            groupName: part.GROUPNO
          });
        });
        parts.push({
          addButton: <Button onClick={this.addPart}>Add Part</Button>
        });
        this.setState({ parts: parts });
      } else {
        Notify('error', 'Not a valid parts list XML');
      }
    });
    reader.readAsText(file);
  };
  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    return (
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <FormItem label={<span>Requisition Number</span>}>
          {this.props.form.getFieldDecorator('requisitionNumber', {
            // rules: [{ required: true, message: 'Description required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.requisitionNumber
              : ''
          })(<Input disabled={true} />)}
        </FormItem>
        <FormItem label={<span>Job</span>}>
          {this.props.form.getFieldDecorator('jobNumber', {
            // rules: [{ required: true, message: 'Job required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.jobNumber
              : undefined
          })(
            <Select
              disabled={true}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              showSearch
              style={{ width: '100%' }}
              onSearch={this.props.handleSearchJob}
              placeholder={'Type in at least 4 characters to start search'}
            >
              {this.props.jobOptions}
            </Select>
          )}
        </FormItem>

        <FormItem label={<span>Description</span>}>
          {this.props.form.getFieldDecorator('description', {
            // rules: [{ required: true, message: 'Description required' }],
            initialValue: this.editRequisition
              ? this.editRequisition.description
              : ''
          })(<Input disabled={true} />)}
        </FormItem>
        <FormItem label={<span>Parts List</span>}>
          <Table
            style={{ background: 'white' }}
            components={components}
            size="middle"
            pagination={{
              defaultPageSize: 10
            }}
            expandedRowRender={
              window.innerWidth <= 420
                ? record => (
                  <Card
                    size="small"
                    title={record.itemCode}
                    style={{ width: 300, marginLeft: -35 }}
                  >
                    <p>Part: {record.itemCode}</p>
                    <p>Qty: {record.qty}</p>
                    <p>Part Name: {record.description}</p>
                    <p>Group Name: {record.groupName}</p>
                    <p>Cost per unit: {record.price}</p>
                  </Card>
                )
                : null
            }
            dataSource={this.state.parts}
          // footer={() => (
          //   <div style={{ width: '100%', display: 'flex' }}>
          //     <Upload
          //       style={{ margin: 'auto' }}
          //       name="XML"
          //       showUploadList={false}
          //       customRequest={this.handleXMLUpload}
          //       accept=".xml"
          //     >
          //       <Button style={{ margin: 'auto' }}>Import XML</Button>
          //     </Upload>
          //   </div>
          // )}
          >
            <Column
              title="No"
              dataIndex="key"
              key="key"
              render={(text, record, index) => {
                if (!record.addButton) {
                  return <span>{index + 1}</span>;
                }
              }}
            />
            {window.innerWidth > 420 ? (
              <Column
                title="Part"
                dataIndex="itemCode"
                key="itemCode"
              // onCell={record => ({
              //   record,
              //   editable: true,
              //   dataIndex: 'itemCode',
              //   title: 'Part',
              //   handleSave: this.handleSave
              // })}
              />
            ) : null}
            {window.innerWidth > 420 ? (
              <Column
                title="Quantity"
                dataIndex="qty"
                key="qty"
              // onCell={record => ({
              //   record,
              //   editable: true,
              //   dataIndex: 'qty',
              //   title: 'Quantity',
              //   handleSave: this.handleSave
              // })}
              />
            ) : null}
            <Column
              title="Part Name"
              dataIndex="description"
              key="description"
            // onCell={record => ({
            //   record,
            //   editable: true,
            //   dataIndex: 'description',
            //   title: 'Description',
            //   handleSave: this.handleSave
            // })}
            />
            {window.innerWidth > 420 ? (
              <Column
                title="Group Name"
                dataIndex="groupName"
                key="groupName"
              // onCell={record => ({
              //   record,
              //   editable: true,
              //   dataIndex: 'groupName',
              //   title: 'Group Name',
              //   handleSave: this.handleSave
              // })}
              />
            ) : null}
            {window.innerWidth > 420 ? (
              <Column
                title="Cost per unit"
                dataIndex="price"
                key="price"
                onCell={record => ({
                  record,
                  editable: true,
                  dataIndex: 'price',
                  title: 'Cost per unit',
                  handleSave: this.handleSave
                })}
              />
            ) : null}
            {/* <Column
              title="Actions"
              key="actions"
              render={(text, requisition) => {
                if (!requisition.addButton) {
                  return (
                    <span>
                      <a onClick={e => this.deletePart(requisition)}>Delete</a>
                    </span>
                  );
                } else {
                  return requisition.addButton;
                }
              }}
            /> */}
          </Table>
        </FormItem>
        <Row type="flex" justify="space-around" align="middle">
          <Button type="primary" onClick={this.props.onOk}>
            {this.props.okText}
          </Button>
          <Button type="primary" onClick={this.props.onCancel}>
            Cancel
          </Button>
        </Row>
      </Form>
    );
  }
}
const RequisitionForm = Form.create()(RequisitionFormClass);
class Requisition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: '',
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      qtyError: false
    };
  }
  updateSignal;
  componentWillMount = () => {
    this.setState({ loading: true });
    this.props
      .findAllRequisition()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllRequisition()
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    };
    window.pubnub.addListener(this.updateSignal);
    window.addEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };

  handleBack = () => {
    if (this.state.addRequisitionVisible) {
      this.setState({ addRequisitionVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener('resize', this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split('R')[1].replace(/,/g, ''));
  };
  formatNumber = number => {
    return 'R' + numeral(number).format('0,0');
  };

  handleSelectRequisition = e => {
    this.props.setRequisitionDetailsId(e.currentTarget.getAttribute('data'));
    this.props.push('/admin/requisitiondetails');
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let requisition = this.state.requisition;
    if (order === 'ascend') {
      requisition.sort(
        (a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey])
      );
    } else if (order === 'descend') {
      requisition.sort(
        (a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey])
      );
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? 'black' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ''
      )
  });
  onAddRequisition = e => {
    this.setState({ addRequisitionVisible: true, editRequisition: undefined });
  };
  onEditRequisition = e => {
    this.setState({ addRequisitionVisible: true, editRequisition: e });
  };
  onSendRequisition = e => {
    this.setState({ sendRequisitionVisible: true, editRequisition: e });
  };
  onSendQuotesRequisition = e => {
    this.setState({ sendQuotesRequisitionVisible: true, editRequisition: e });
  };
  handleSendRequisition = () => {
    this.setState({ loading: true });
    let requisition = this.state.editRequisition;
    requisition.status = 'Sent to Supplier';
    this.props
      .addRequisition(requisition)
      .then(() => {
        fetch(
          process.env.REACT_APP_GLOBAL_SERVER +
          'troef-die-skaal/api/requisition/sendrequisition',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              Authorization: 'Bearer ' + getToken(),
              'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
              requisition: requisition,
              suppliers: this.state.supplierEmail
            })
          }
        )
          .then(async res => {
            if (!res.ok) {
              this.setState({ loading: false });
              Notify('error', await res.text());
            }
            this.props
              .findAllRequisition()
              .then(() => {
                this.setState({ loading: false });
                this.setState({
                  sendRequisitionVisible: false,
                  editRequisition: undefined,
                  supplierEmail: undefined
                });
                Notify('success', 'Successfully sent the requisition');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleSendQuotesRequisition = () => {
    this.setState({ loading: true });
    let requisition = this.state.editRequisition;
    requisition.status = 'Sent to Quotes Department';
    this.props
      .addRequisition(requisition)
      .then(() => {
        fetch(
          process.env.REACT_APP_GLOBAL_SERVER +
          'troef-die-skaal/api/requisition/sendquotesrequisition',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              Authorization: 'Bearer ' + getToken(),
              'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
              requisition: requisition,
              suppliers: this.state.quotesEmail
            })
          }
        )
          .then(async res => {
            if (!res.ok) {
              this.setState({ loading: false });
              Notify('error', await res.text());
            }
            this.props
              .findAllRequisition()
              .then(() => {
                this.setState({ loading: false });
                this.setState({
                  sendQuotesRequisitionVisible: false,
                  editRequisition: undefined,
                  quotesEmail: undefined
                });
                Notify('success', 'Successfully sent the requisition');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e.message);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  onEditRequisitionApprove = requisition => {
    this.setState({ loading: true });
    if (requisition.status === 'In Request') {
      requisition.qty = parseInt(requisition.qty) + 1;
    } else if (requisition.status === 'Out Request') {
      requisition.qty = parseInt(requisition.qty) - 1;
    }
    requisition.status = 'None';
    this.props
      .addRequisition(requisition)
      .then(() => {
        this.props
          .findAllRequisition()
          .then(() => {
            this.props
              .sendNotification(
                requisition.requestId,
                [requisition.requestId],
                'Admin has approved one of your requests',
                'adminChanged',
                requisition._id,
                false,
                'troef-die-skaal Admin'
              )
              .then(() => {
                this.setState({ loading: false });
                Notify('success', 'Successfully approved request');
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleAddRequisition = e => {
    e.preventDefault();
    this.requisitionForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editRequisition) {
          values._id = this.state.editRequisition._id;
        }
        values.parts = this.requisitionForm.state.parts;
        this.props
          .addRequisition(values)
          .then(() => {
            this.props
              .findAllRequisition()
              .then(() => {
                if (this.state.editRequisition) {
                  Notify('success', 'Successfully saved requisition');
                } else {
                  Notify('success', 'Successfully added requisition');
                }
                this.requisitionForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addRequisitionVisible: false,
                  editRequisition: undefined
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify('error', e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  onEditRequisitionOutVisible = requisition => {
    if (parseInt(requisition.qty) === 0) {
      Notify('error', 'There is no requisition left');
      return;
    }
    this.setState({
      requisitionOut: requisition,
      outVisible: true
    });
  };
  onEditRequisitionOut = () => {
    let requisition = this.state.requisitionOut;
    if (this.state.qtyError === true || this.state.qty === 0) {
      return;
    }
    if (parseInt(requisition.qty) === 0) {
      Notify('error', 'There is no requisition left');
      return;
    }
    const id = requisition._id;
    let request = {
      qty: this.state.qty,
      jobNumber: this.state.jobNumber,
      requisitionId: id,
      type: 'Out',
      itemCode: requisition.itemCode,
      description: requisition.description,
      userName: this.state.userName,
      requisitionNumber: this.state.requisitionNumber,
      approved: true
    };
    this.setState({ loading: true, outVisible: false });
    this.props
      .addRequest(request)
      .then(() => {
        this.props
          .findAllRequisition()
          .then(() => {
            this.setState({ loading: false, qty: 1, qtyError: false });
            Notify('success', 'Successfully submitted');
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleDeleteRequisition = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteRequisition;
    this.props
      .deleteRequisition(data._id)
      .then(() => {
        this.props
          .findAllRequisition()
          .then(() => {
            this.setState({ loading: false });
            Notify(
              'success',
              'Deleted requisition',
              'Successfully deleted requisition.'
            );
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', e);
      });
  };
  handleSearchJob = e => {
    if (e.length > 3) {
      this.props
        .findJobNumbers(e)
        .then(suc => {
          this.setState({
            jobNumbers: suc.jobNumbers
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else {
      this.setState({
        jobNumbers: []
      });
    }
  };
  handleSearchTechnician = e => {
    if (e.length > 3) {
      this.props
        .findTechnicians(e)
        .then(suc => {
          this.setState({
            technicians: suc.technicians
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify('error', e);
        });
    } else {
      this.setState({
        technicians: []
      });
    }
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteRequisition: '' });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== '' && setQty !== null) {
      if (this.state.requisitionOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false
      });
    }
  };
  render() {
    const { requisition } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + ' - ' + d.username.split('|')[1]}
      </Option>
    ));
    return (
      <div>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{ textAlign: 'center' }}
        >
          <Col xs={24} md={12} style={{ marginLeft: '-0.8em' }}>
            <LegacyIcon
              style={{ fontSize: 25, color: '#EC8144', marginRight: 7 }}
              type="requisition"
            />
            <span style={{ fontWeight: 750, fontSize: 30 }}>
              {this.state.addRequisitionVisible
                ? 'Add Requisition'
                : 'Manage Requisitions'}
            </span>
          </Col>
          {/* <Col
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            xs={24}
            md={12}
          >
            {!this.state.addRequisitionVisible ? (
              <div
                style={{
                  margin: 'auto',
                  marginTop: 5,
                  marginBottom: 5
                }}
              >
                <Button
                  type="primary"
                  style={{ width: 170, height: '3em' }}
                  onClick={this.onAddRequisition}
                >
                  Add Requisition
                </Button>
              </div>
            ) : null}
          </Col> */}
        </Row>
        <Spin spinning={this.state.loading}>
          {this.state.addRequisitionVisible ? (
            <Row
              style={{ background: 'white', borderRadius: 5, marginTop: 20 }}
              type="flex"
              justify="space-around"
              align="middle"
            >
              <Col style={{ padding: 20 }} span={20}>
                <RequisitionForm
                  handleSearchJob={this.handleSearchJob}
                  jobOptions={jobOptions}
                  editRequisition={this.state.editRequisition}
                  wrappedComponentRef={form => (this.requisitionForm = form)}
                  onOk={this.handleAddRequisition}
                  onCancel={e =>
                    this.setState({ addRequisitionVisible: false })
                  }
                  okText={
                    this.state.editRequisition ? 'Save' : 'Add Requisition'
                  }
                />
              </Col>
            </Row>
          ) : (
            <Row type="flex" justify="start" align="middle">
              <Col span={24}>
                <StyledTable
                  style={{ background: 'white' }}
                  rowKey="_id"
                  size="middle"
                  pagination={{
                    defaultPageSize: 25
                  }}
                  expandedRowRender={
                    window.innerWidth <= 420
                      ? record => (
                        <Card
                          size="small"
                          title={record.itemCode}
                          style={{ width: 300, marginLeft: -35 }}
                        >
                          <p>
                            Requisition Number: {record.requisitionNumber}
                          </p>
                          <p>Job Number: {record.jobNumber}</p>
                          <p>Description: {record.description}</p>
                          <p>Parts: {record.parts.length}</p>
                          <p>Timestamp: {record.timestamp}</p>
                        </Card>
                      )
                      : null
                  }
                  dataSource={this.props.requisition}
                  onChange={this.handleSort}
                  onRow={record => {
                    if (parseInt(record.qty) === 0) {
                      return { style: { background: '#ffd3d3' } };
                    }
                  }}
                >
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('requisitionNumber')}
                      title="Requisition Number"
                      dataIndex="requisitionNumber"
                      key="requisitionNumber"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('jobNumber')}
                      title="Job Number"
                      dataIndex="jobNumber"
                      key="jobNumber"
                    />
                  ) : null}
                  <Column
                    {...this.getColumnSearchProps('description')}
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />

                  {window.innerWidth > 420 ? (
                    <Column
                      title="Parts"
                      dataIndex="parts"
                      key="parts"
                      render={(text, record) => (
                        <span>{record.parts.length}</span>
                      )}
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      {...this.getColumnSearchProps('timestamp')}
                      title="Timestamp"
                      dataIndex="timestamp"
                      key="timestamp"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      title="Status"
                      filters={[
                        { text: 'None', value: 'None' },
                        {
                          text: 'Awaiting Approval',
                          value: 'Awaiting Approval'
                        }
                      ]}
                      filteredValue={filteredInfo.status || null}
                      onFilter={(value, record) =>
                        record.status.includes(value)
                      }
                      dataIndex="status"
                      key="status"
                      render={(text, record) => (
                        <span
                          style={{
                            color:
                              text === 'Awaiting Approval' ? 'orange' : 'green'
                          }}
                        >
                          {text}
                        </span>
                      )}
                    />
                  ) : null}

                  <Column
                    title="Actions"
                    render={(text, requisition) => (
                      <span>
                        <a onClick={e => this.onEditRequisition(requisition)}>
                          Set Prices
                        </a>
                        <Divider type="vertical" />
                        <a onClick={e => this.onSendRequisition(requisition)}>
                          Send to Suppliers
                        </a>
                        <Divider type="vertical" />
                        <a
                          onClick={e =>
                            this.onSendQuotesRequisition(requisition)
                          }
                        >
                          Send to Quotes
                        </a>
                        {/* <Divider type="vertical" />
                          <a
                            onClick={e => {
                              this.setState({
                                deleteVisible: true,
                                deleteRequisition: requisition
                              });
                            }}
                          >
                            Delete
                        </a> */}
                      </span>
                    )}
                  />
                </StyledTable>
              </Col>
            </Row>
          )}
        </Spin>
        {/* <Modal
          title="Add Requisition"
          visible={this.state.addRequisitionVisible}
          onOk={this.handleAddRequisition}
          onCancel={e => this.setState({ addRequisitionVisible: false })}
          okText={this.state.editRequisition ? 'Save' : 'Add Requisition'}
          cancelText="Cancel"
        >
          <RequisitionForm
            editRequisition={this.state.editRequisition}
            wrappedComponentRef={form => (this.requisitionForm = form)}
          />
        </Modal> */}
        <Modal
          title={'Delete requisition'}
          visible={this.state.deleteVisible}
          onOk={this.handleDeleteRequisition}
          onCancel={this.cancelDelete}
          okText="Accept"
          cancelText="Cancel"
        >
          <span>Are you sure you want to delete this requisition?</span>
        </Modal>
        <Modal
          title={'Requisition Out'}
          visible={this.state.outVisible}
          onOk={this.onEditRequisitionOut}
          onCancel={e =>
            this.setState({
              outVisible: false,
              qty: 1,
              qtyError: false
            })
          }
          okText="Submit"
          cancelText="Cancel"
        >
          <span>Qty</span>
          <InputNumber
            min={1}
            style={{ marginBottom: 15, width: '100%' }}
            onChange={this.checkQty}
            value={this.state.qty}
          />
          {this.state.qtyError ? (
            <p style={{ color: 'red', width: '100%' }}>
              There is not enough requisition to satisfy this quantity
            </p>
          ) : null}
          <span>Requisition Number</span>
          <Input
            style={{ marginBottom: 15, width: '100%' }}
            value={this.state.requisitionNumber}
            onChange={e => this.setState({ requisitionNumber: e.target.value })}
          />
          <span>Assign requisition to technician</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: '100%', marginBottom: 14 }}
            onSearch={this.handleSearchTechnician}
            value={this.state.userName}
            placeholder={'Type in at least 4 characters to start search'}
            onChange={e => this.setState({ userName: e })}
          >
            {technicianOptions}
          </Select>
          <span>Assign requisition to job</span>
          <Select
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            showSearch
            style={{ width: '100%' }}
            onSearch={this.handleSearchJob}
            value={this.state.jobNumber}
            placeholder={'Type in at least 4 characters to start search'}
            onChange={e => this.setState({ jobNumber: e })}
          >
            {jobOptions}
          </Select>
        </Modal>
        <Modal
          title={'Send Requisition to Suppliers'}
          visible={this.state.sendRequisitionVisible}
          onOk={this.handleSendRequisition}
          onCancel={e =>
            this.setState({
              sendRequisitionVisible: false,
              editRequisition: undefined
            })
          }
          okText="Send"
          cancelText="Cancel"
        >
          <span>Suppliers Emails</span>
          <Input
            style={{ marginBottom: 15, width: '100%' }}
            value={this.state.supplierEmail}
            placeholder="Seperate emails with ,"
            onChange={e => this.setState({ supplierEmail: e.target.value })}
          />
        </Modal>
        <Modal
          title={'Send Requisition to Quotes'}
          visible={this.state.sendQuotesRequisitionVisible}
          onOk={this.handleSendQuotesRequisition}
          onCancel={e =>
            this.setState({
              sendQuotesRequisitionVisible: false,
              editRequisition: undefined
            })
          }
          okText="Send"
          cancelText="Cancel"
        >
          <span>Quotes Department Emails</span>
          <Input
            style={{ marginBottom: 15, width: '100%' }}
            value={this.state.quotesEmail}
            placeholder="Seperate emails with ,"
            onChange={e => this.setState({ quotesEmail: e.target.value })}
          />
        </Modal>
      </div>
    );
  }
}

export default Requisition;
