import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import Logo from "src/static/logo3.svg";
import Background from "src/static/sign-in-background.png";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Checkbox,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Modal,
  Card,
} from "antd";
import { ButtonRadius } from "src/components/elements/Button";
import Notify from "src/components/meta/Notification";
import lock from "src/static/icons/input-icons/lock.svg";
import user from "src/static/icons/input-icons/user.svg";
import eye from "src/static/icons/password-show.svg";

const FormItem = Form.Item;
const Inputs = styled(Input)`
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border-radius: 3em !important;
    border: 1px solid lightgrey!important;
    outline: none;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-left: 30px !important;
    border-radius: 3em !important;
}
  .ant-input-affix-wrapper .ant-input {
    min-height: 100%;
    padding-left: 30px !important;
    border: 1px solid lightgrey!important;
    border-radius: 3em !important;
  }
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.13);
  height: 3.7em;
  padding-left: 2em;
  border-radius: 3em !important;
  border: 1px solid lightgrey!important;
  //.ant-input:focus {
  //  border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  //}
`;
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
class PassFormClass extends React.Component {
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Password</span>}>
          {this.props.form.getFieldDecorator("password", {
            rules: [
              { min: 5, message: "Password must be minimum 5 characters." },
              { required: true, message: "Password required" },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </FormItem>
        <FormItem label={<span>Confirm Password</span>}>
          {this.props.form.getFieldDecorator("confirmpassword", {
            rules: [
              { required: true, message: "Confirm Password" },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password min={4} />)}
        </FormItem>
      </Form>
    );
  }
}
const PassForm = Form.create()(PassFormClass);
class SignIn extends React.Component {
  static defaultProps = {
    error: null,
    firstLogin: false,
  };
  componentWillMount = () => {
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  };
  exit = false;
  handleBack = () => {
    Notify("info", "Press back again to close the app", "", 2);
    if (this.exit) {
      navigator.app.exitApp();
    }
    this.exit = true;
    setTimeout(() => {
      this.exit = false;
    }, 2000);
  };
  componentWillUnmount = () => {
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };
  handleSubmit = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.type = "Technician";
        this.signIn(values);
      }
    });
  };

  signIn = (values, props) => {
    this.props
      .signIn(values)
      .then(() => {
        this.setState({ loading: false });
        if (this.props.user.firstLogin) {
          this.setState({ firstLogin: true });
        } else if (this.props.user.type === "admin") {
          this.props.push("/admin/stock");
        } else if (this.props.user.type === "manager") {
          this.props.push("/manager/dashboard");
        } else if (
          this.props.user.type === "user" &&
          this.props.user.paymentStatus === "Allow"
        ) {
          this.props.push("/user/stock");
        } else if (this.props.user.type === "buyer") {
          this.props.push("/buyer/requisition");
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", "Error", e);
      });
  };

  toggleShowPass = () => {
    const { state } = this;
    this.state.passShow === "password"
      ? this.setState({ passShow: "text" })
      : this.setState({ passShow: "password" });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: "password",
    };
  }

  passForm;
  handleChangePassword = e => {
    e.preventDefault();
    this.passForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            Notify("success", "Successfully set password");
            this.setState({ loading: false, firstLogin: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      // <Section className="panel" {...this.props} >
      <div
        style={{
          background: "white",
          // height: "100vh",
        }}
      >
        {/*<img
          src={Background}
          style={{ position: "absolute", right: 0, top: 0 }}
        />*/}
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ marginTop: "23vh" }}
        >
          {" "}
          <Col xs={22} md={24}>
            <Spin spinning={this.state.loading}>
              <Form onSubmit={this.handleSubmit}>
                <Card
                  style={{
                    margin: "auto",
                    marginTop: "1.5em",
                    borderRadius: "1em",
                    background: "white",
                    textAlign: "center",
                    maxWidth: "30em",
                    //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
                    border: "none",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      marginTop: "-30px",
                      marginBottom: "30px",
                      color: "black",
                      fontSize: "35px",
                      fontWeight: 600,
                    }}
                  >
                    4-Week Challenge Login
                  </p>

                  <FormItem
                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Username
                  //  </span>
                  //}
                  >
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          //required: true,
                          //message: "Username required"
                        },
                      ],
                    })(
                      <Inputs
                        placeholder="Email"
                        // prefix={
                        //   <UserOutlined
                        //     style={{
                        //       paddingRight: '0.5em',
                        //       color: 'lightgrey'
                        //     }}
                        //   />
                        // }
                        style={{
                          width: "100%",
                          border: 0,
                          borderRadius: "3em ",
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem
                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Password
                  //  </span>
                  //}
                  >
                    {getFieldDecorator("password", {
                      rules: [{ required: true, message: "Password required" }],
                    })(
                      <Inputs.Password
                        placeholder="Password"
                        // prefix={
                        //   <LockOutlined
                        //     style={{
                        //       paddingRight: '0.5em',
                        //       color: 'lightgrey'
                        //     }}
                        //   />
                        // }
                        type="password"
                        style={{
                          boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
                          height: "3.7em",
                          border: "1px solid grey",
                          borderRadius: "3em ",
                          border: "none",
                        }}
                      />
                    )}
                  </FormItem>
                  {/* <span style={{ fontSize: '13px' }}>
                    New User? Enter{' '}
                    <span
                      style={{ color: 'black', fontWeight: 600 }}
                    >
                      1234
                    </span>{' '}
                    to create a password
                  </span> */}
                  {/*<FormItem

                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Password
                  //  </span>
                  //}
                  >
                    {getFieldDecorator("password", {
                      //rules: [{ message: "Please read & accept Terms" }],
                    })(
                      <Checkbox
                        onChange={onChange}
                        style={{ fontFamily: "Open Sans" }}
                      >
                        I agree the
                        <span style={{ fontWeight: 900 }}>
                          {" "}
                          Terms and Conditions
                        </span>
                      </Checkbox>
                    )}
                  </FormItem>*/}
                  <FormItem>
                    <ButtonRadius
                      style={{
                        marginTop: "1em",
                        height: 40,
                        width: "100%",
                        fontSize: 14,
                        border: "none",
                        fontFamily: "Open Sans",
                        textShadow: "none",
                        borderRadius: "0.2em ",
                        backgroundColor: "rgb(18, 18, 18)",
                      }}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                    >
                      Login
                    </ButtonRadius>
                  </FormItem>
                  {/* Don't have an account? <Link to="/request">Sign Up</Link> */}
                </Card>
              </Form>
            </Spin>
          </Col>
        </Row>
        <Modal
          title="Set Password"
          visible={this.state.firstLogin}
          onOk={this.handleChangePassword}
          onCancel={e => this.setState({ firstLogin: false })}
          okText="Save"
          cancelText="Cancel"
        >
          <PassForm wrappedComponentRef={form => (this.passForm = form)} />
        </Modal>
      </div>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;
